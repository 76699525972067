<template>
  <div id="email-verification">
    <auth-forms-wrapper show-links width="390px" card-classes="px-0">
      <template #form>
        <alert-message
          v-show="!!message"
          :message="message"
          classes="py-3 mb-4"
          :alert-type="messageType"
        />

        <div class="email-verification__header">
          <h3
            class="text-h6 text--primary font-weight-large secondary--font mb-4"
            v-text="title"
          />
        </div>
        <div class="text-left body-2" id="email-verification__content">
          <div class="body-2" v-text="subTitle" />
        </div>
        <div class="d-flex justify-center align-center mt-8 mb-4">
          <v-btn
            block
            x-large
            to="/login"
            type="button"
            :loading="isLoading"
            color="dark-black"
            class="font-weight-bold white--text"
            v-track="'email-verification-to-login-btn'"
          >
            Back to Login
          </v-btn>
        </div>
      </template>
    </auth-forms-wrapper>
  </div>
</template>

<script>
import { applyActionCode } from "@/services/auth";
import { AUTH_ERROR_CASE_MESSAGES } from "@/constants/app";
import AlertMessage from "@/components/shared/AlertMessage.vue";

import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";

export default {
  name: "VerifyEmailAddress",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { AuthFormsWrapper, AlertMessage },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      isLoading: false,
      messageType: "error",
      message: "",
      isEmailVerified: false,
      isLinkValid: true,
    };
  },
  /**
  |--------------------------------------------------
  | Computed Properties
  |--------------------------------------------------
  */
  computed: {
    /**
     * title
     * @description Component title
     */
    title() {
      if (!this.isLinkValid) {
        return `Try verifying your email again`;
      } else {
        return this.isLoading && !this.isEmailVerified
          ? `Verifying your email`
          : `Your email has been verified`;
      }
    },
    subTitle() {
      return this.isLinkValid
        ? `You can now sign in with your new account`
        : `Your request to verify your email has expired or the link has already been used`;
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * showMessage
     * @description Shows messages in alert bar
     */
    showMessage(message, type = "error") {
      this.messageType = type;
      this.message = message;
    },
    /**
     * errorHandler
     * @description Api's error handler
     */
    errorHandler({ message = "", code = "" }) {
      const invalidCodes = [
        "auth/invalid-action-code",
        "auth/expired-action-code",
      ];
      if (invalidCodes.includes(code)) {
        this.isLinkValid = false;
      } else {
        this.showMessage(AUTH_ERROR_CASE_MESSAGES[code] ?? message);
      }
    },
    /**
     * verifyEmailAddress
     * @description Verifies Email address of the user
     */
    async verifyEmailAddress() {
      try {
        const { oobCode } = this.$route.query;
        if (!oobCode) return;

        this.isLoading = true;
        await applyActionCode(oobCode);
        this.isEmailVerified = true;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.verifyEmailAddress();
  },
};
</script>
