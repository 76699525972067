<template>
  <div id="email-action-handler">
    <template v-if="modeDetails.component">
      <component :is="modeDetails.component" />
    </template>
  </div>
</template>

<script>
import { isEmpty } from "@/utils";
import { EMAIL_ACTION_CODES } from "@/constants/app";
import VerifyEmailAddress from "@/views/EmailActionHandler/VerifyEmailAddress.vue";

import RevertSecondFactorAddition from "@/views/EmailActionHandler/RevertSecondFactorAddition.vue";

/**
 * EmailActionHandler
 * @description Handle email actions
 * @author {Jatin Kamboj}
 */
export default {
  name: "EmailActionHandler",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { VerifyEmailAddress, RevertSecondFactorAddition },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      emailModes: {
        verifyEmail: {
          mode: EMAIL_ACTION_CODES.verifyEmail,
          component: "VerifyEmailAddress",
        },
        revertSecondFactorAddition: {
          mode: EMAIL_ACTION_CODES.revertSecondFactorAddition,
          component: "RevertSecondFactorAddition",
        },
      },
    };
  },
  /**
  |--------------------------------------------------
  | Computed propertues
  |--------------------------------------------------
  */
  computed: {
    emailMode() {
      return this.$route?.query?.mode;
    },
    modeDetails() {
      return this.emailModes[this.emailMode];
    },
  },
  /**
  |--------------------------------------------------
  | created
  | Redirects user to login page if current email mode is invalid
  |--------------------------------------------------
  */
  created() {
    if (isEmpty(this.modeDetails)) {
      this.$router.replace("/login");
    }
  },
};
</script>
