<template>
  <div id="revert-mfa__wrapper">
    <v-row no-gutters class="d-flex justify-center">
      <auth-forms-wrapper show-links width="390px" card-classes="px-0">
        <template #form>
          <alert-message
            v-show="!!message"
            :message="message"
            classes="py-3 mb-4"
            :alert-type="messageType"
          />
          <div class="revert-mfa__header">
            <h3
              class="
                text-h6 text--primary
                font-weight-large
                secondary--font
                mb-4
              "
            >
              Remove second factor
            </h3>
            <div class="text-left body-2" id="revert-mfa__content">
              <div class="body-2">
                The phone {{ phoneNumber }} will be removed as a second
                authentication step.
              </div>

              <div class="mt-2">
                If you don't recognize this device, someone might be trying to
                access your account.
              </div>
            </div>
          </div>

          <div class="d-flex justify-center align-center mt-8 mb-4">
            <v-btn
              block
              x-large
              type="button"
              color="dark-black"
              :loading="isLoading"
              :disabled="!isOobCodeValid"
              @click="applyActionCodeHandler"
              class="font-weight-bold white--text"
              v-track="'revert-mfa-submit-btn'"
            >
              Submit
            </v-btn>
          </div>
          <div class="pt-2 text-center">
            <router-link
              to="/login"
              class="text-decoration-none"
              v-track="'revert-mfa-back-to-login-link'"
            >
              <strong class="subtitle-2 text--secondary font-weight-bold">
                Back to Login
              </strong>
            </router-link>
          </div>
        </template>
      </auth-forms-wrapper>
    </v-row>
  </div>
</template>

<script>
import { defer, isEmpty } from "@/utils";
import {
  AUTH_ERROR_CASE_MESSAGES,
  AUTH_GIP_ERROR_CODES,
} from "@/constants/app";
import AlertMessage from "@/components/shared/AlertMessage.vue";

import { applyActionCode, checkActionCode } from "@/services/auth";
import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";

/**
 * EmailActionHandler
 * @description Handle email actions
 * @author {Jatin Kamboj}
 */
export default {
  name: "RevertSecondFactorAddition",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { AuthFormsWrapper, AlertMessage },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      oobCodeDetails: null,
      isLoading: false,
      messageType: "error",
      message: "",
      timerRef: null,
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    /**
     * oobCode
     * @type {String}
     */
    oobCode() {
      return this.$route.query?.oobCode ?? "";
    },
    phoneNumber() {
      return this.oobCodeDetails?.data?.multiFactorInfo?.phoneNumber ?? "";
    },
    isOobCodeValid() {
      return !isEmpty(this.oobCodeDetails);
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * showMessage
     * @description Shows messages in alert bar
     */
    showMessage(message, type = "error") {
      this.messageType = type;
      this.message = message;
    },
    /**
     * errorHandler
     * @description Api's error handler
     */
    errorHandler({ message = "", code = "" }) {
      if (message === "Invalid checkActionCode response!") {
        this.errorHandler({
          code: AUTH_GIP_ERROR_CODES.invalidUserActionCode,
        });
      } else {
        this.showMessage(AUTH_ERROR_CASE_MESSAGES[code] ?? message);
      }
    },
    /**
     * verifyOobCode
     * @description Verifies oobcode
     */
    async verifyOobCode() {
      try {
        if (!this.oobCode) return;
        this.isLoading = true;

        const info = await checkActionCode(this.oobCode);
        this.oobCodeDetails = info;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * applyActionCodeHandler
     * @description Applies action code in the backedn to perform an action
     */
    async applyActionCodeHandler() {
      try {
        this.isLoading = true;
        await applyActionCode(this.oobCode);

        const message = `The phone number ${this.phoneNumber} has been removed as a second factor from your account.`;
        this.showMessage(message, "success");
        this.redirectToLogin();
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * redirectToLogin
     * @description Redirects user to login page
     */
    redirectToLogin() {
      const REDIRECT_MILLI_SECONDS = 5000;
      const callback = () => this.$router.replace("/login");

      this.timerRef = defer(callback, REDIRECT_MILLI_SECONDS);
    },
  },
  /**
  |--------------------------------------------------
  | Created lifeycle hook
  |--------------------------------------------------
  */
  created() {
    this.verifyOobCode();
  },
  /**
  |--------------------------------------------------
  | Destroyed lifecyle hook
  |--------------------------------------------------
  */
  destroyed() {
    if (this.timerRef) clearTimeout(this.timerRef);
  },
};
</script>
